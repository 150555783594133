<template>
  <div class="home">
    <!-- 顶部导航 -->
    <el-header class="header">
      <div class="logo"><img src="../assets/logo1.png" alt="企业LOGO" style="width: 200px; height: 80px; object-fit: contain;"></div>
      <el-menu
        mode="horizontal"
        :default-active="activeIndex"
        class="nav-menu"
        @select="handleSelect"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">产品服务</el-menu-item>
        <el-menu-item index="3">品牌介绍</el-menu-item>
        <el-menu-item index="4">团队介绍</el-menu-item>
        <el-menu-item index="5">新闻动态</el-menu-item>
        <el-menu-item index="6">联系我们</el-menu-item>
      </el-menu>
    </el-header>

    <!-- 轮播图 -->
    <el-carousel height="500px">
      <el-carousel-item>
        <div class="banner-item">
          <img src="../assets/banner1.jpg" alt="Banner 1">
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="banner-item">
          <img src="../assets/banner2.jpg" alt="Banner 2">
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="banner-item">
          <img src="../assets/banner3.jpg" alt="Banner 3">
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="banner-item">
          <img src="../assets/banner4.jpg" alt="Banner 4">
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <!-- 产品服务 -->
      <section class="section">
        <h2>产品服务</h2>
        <el-row :gutter="20">
          <el-col :span="8" v-for="i in 3" :key="i">
            <el-card>
              <h3>产品 {{ i }}</h3>
              <p>产品描述内容...</p>
            </el-card>
          </el-col>
        </el-row>
      </section>

      <!-- 关于我们 -->
      <section class="section">
        <h2>关于我们</h2>
        <el-row>
          <el-col :span="24">
            <div class="about-content">
              <p>公司简介内容...</p>
            </div>
          </el-col>
        </el-row>
      </section>

      <!-- 新闻动态 -->
      <section class="section">
        <h2>新闻动态</h2>
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :timestamp="activity.timestamp"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </section>
    </div>

    <!-- 页脚 -->
    <el-footer class="footer">
      <p>版权所有 © 2024 AKJD</p>
    </el-footer>
  </div>
</template>

<script>
export default {
    name: "HomeView"
};
</script>

<script setup>
import { ref } from 'vue'

const activeIndex = ref('1')

const activities = ref([
  {
    content: '新闻标题1',
    timestamp: '2024-03-15'
  },
  {
    content: '新闻标题2',
    timestamp: '2024-03-14'
  },
  {
    content: '新闻标题3',
    timestamp: '2024-03-13'
  }
])

const handleSelect = (key) => {
  console.log(key)
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav-menu {
  border-bottom: none;
}

.banner-item {
  height: 100%;
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.about-content {
  text-align: center;
  padding: 20px;
}

.footer {
  background-color: #545c64;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}
</style>