<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="$route.fullPath" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  background-color: #333;
  padding: 15px;
  color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #0070c9;
}

.hero {
  background: url('#') no-repeat center center/cover;
  color: rgb(29, 25, 25);
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.hero-content {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.button {
  background-color: #0070c9;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.button:hover {
  background-color: #005ba1;
}

.products {
  display: flex;
  justify-content: space-around;
  padding: 60px 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.product {
  text-align: center;
  max-width: 300px;
  margin: 20px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.footer {
  background-color: #333;
  color: white;
  padding: 30px;
  text-align: center;
}

.footer-links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #0070c9;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
