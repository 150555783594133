import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
];

// history 属性使用了 createWebHistory，它基于浏览器的 History API，可以生成一个不带 # 的美观 URL。
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;